import { Container } from "./styles";
// import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";
import EatSeekLink from "../../assets/EatSeek.jpg";

export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

      <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://omnistudy.io" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>OmniStudy - AI Education Platform</h3>
              <p>
                As a consumer-facing revolutionary edtech platform, user experience is at the heart of OmniStudy's success. I designed the UI/UX for the platform, ensuring that the user's journey is seamless and engaging. The platform is designed to help students learn and study more effectively. 
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>UI/UX</li>
                <li>Figma</li>
                <li>Adobe Illustrator</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href={EatSeekLink} target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>EatSeek - Food Ordering & Delivery</h3>
              <p>
                EatSeek is a food delivery app that connects users with local restaurants. Food delivery is a trillion-dollar industry, which means that providing users with the best experience possible has a direct correlation to the success of the platform. 
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>UI/UX</li>
                <li>Adobe Photoshop</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

      </div>
    </Container>
  );
}