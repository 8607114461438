import { Container } from "./styles";
import headshot from "../../assets/headshot.jpeg";
import ScrollAnimation from "react-animate-on-scroll";
import Resume from "../../assets/Ashlynn Resume.pdf";

import ui from "../../assets/ui-icon.png";
import ux from "../../assets/ux-icon.png";
import figma from "../../assets/figma-icon.png";
import photoshop from "../../assets/adobe-photoshop.png";
import illustrator from "../../assets/adobe-illustrator.png";
import indesign from "../../assets/adobe-indesign.png";
import sketch from "../../assets/sketch-icon.png";
import htmlIcon from "../../assets/html-icon.svg";
import cssIcon from "../../assets/css-icon.svg";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            Hey there! I'm Ashlynn, a UI/UX Designer passionate about crafting captivating digital experiences. I excel at understanding user behavior and contribute to projects that prioritize user-centric design.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
          I'm proficient in Adobe Creative Cloud (Photoshop, InDesign, Illustrator & more), Sketch, and Figma, with basic HTML/CSS knowledge for prototyping and front-end development.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          Though new to the industry, I've shown my ability to learn quickly and adapt through projects with OmniStudy, an online AI-edtech platform. My goal is to continue growing as a designer, refining my skills, and delivering impactful solutions that boost user satisfaction and drive business success!</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are some of my skills:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.15 * 1000}>
              <img src={ui} alt="Vue" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={ux} alt="Typescript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
              <img src={figma} alt="Wordpress" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.12 * 1000}>
              <img src={photoshop} alt="shopify" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={illustrator} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={indesign} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <a href="https://www.sketch.com/" target="_blank"><img src={sketch} alt="React" /></a>
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.17 * 1000}>
              <img src={htmlIcon} alt="Html" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.18 * 1000}>
              <img src={cssIcon} alt="Css" />
            </ScrollAnimation>
          </div>
        </div>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
          <p className="resume-link">Click <a href={Resume}>here</a> for a full list of my skills.</p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={headshot} alt="Vinayak Singh" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}
